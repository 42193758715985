@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/sizes';
@import '../../../stylesheets/base/fonts';
@import '../../../stylesheets/base/spacing';

.EcosystemHeader {
  background-color: $white;
  width: calc(100vw - 17px); /* minus scrollbar width */
  flex:inherit;
  position: fixed;
  z-index: 99;

  &__EcosystemHeaderWrapper {
    display: flex;
    grid-area: header;
    grid-template-areas: 'logo links auth toggle';
    grid-template-columns: 1fr auto auto auto;
    grid-template-rows: 2fr;
    height: $ecosystem-header-height;
    position: relative; /* Added */
    align-items: center; /* Add this line */
    justify-content: space-between;

    &__HeaderLinks {
      align-self: center;
      grid-area: links;
      display: flex;
      margin-left: -10rem; /* Adjust this value as needed to move the links to the left */
      justify-content: flex-end;
      justify-self: right;
    }

    &__MiddleLink {
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 2px solid $black;
      a {
        color: $black;
        text-decoration-line: none;
        font-weight: bolder;

        &:hover {
          color: $bright-orange;
        }
      }
    }

    &__HeaderLink {
      padding-right: 1rem;
      margin-right: 1rem;
      color: $light-grey;
      font-size: $body-baseline;
      margin-left: $spacing-standard;
      border-right: 1px solid #fff;
      text-decoration: none;
    }

    &__Login {
      margin-right: 0;
      color: $black;
      font-size: $body-baseline;
      margin-left: $spacing-small;
      // border-right: 1px solid #fff;
      text-decoration: none;
      font-weight: bolder;
    }

    &__Logo {
      align-items: center;
      align-self: center;
      color: $light-grey;
      display: flex;
      grid-area: logo;
      justify-self: start;
      //Boutique Modified
      text-decoration: none;
      cursor: pointer;
      font-weight: bolder;
      margin-left: 0;
      margin-right: $spacing-small;
    }

    &__Logo-text {
      margin-left: $spacing-small;

      &--orange {
        color: $bright-orange;
      }
      &--white {
        color: $white;
      }
      &--black {
        color: $black;
      }
    }
  }
}

.logout {
  display: inline-block;
}

.logout:hover {
  color: $bright-orange;
}

@media screen and (max-width: 800px) {
  .EcosystemHeader {
    width: 100%;
  }
} 

// media query for phones
@media screen and (max-width: 390px) {
  .EcosystemHeader__EcosystemHeaderWrapper {
    display: grid;
    grid-area: header;
    grid-template-areas: 'logo links auth toggle';
    grid-template-columns: 3fr 10fr auto auto;
    justify-items: start;
    grid-template-rows: 2fr;
    height: $ecosystem-header-height;
    position: relative;
    align-items: center; 
  }

  .dropdownContainer {
    position: relative;
    grid-area: toggle;
    justify-self: end;
    display: flex; /* added to make this a flex container */
    flex-direction: column; /* if your links are stacked vertically */
    justify-content: center; /* centers items along the main axis (vertically if flex-direction is column) */
    align-items: center; /* centers items along the cross axis (horizontally if flex-direction is column) */
  }
  
  .dropdownToggle {
    grid-area: toggle;
    display: block;
    justify-self: end;
    align-self: center;
    z-index: 1;
  }
  
  .dropdownToggleLine {
    width: 35px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
  }

  .EcosystemHeader__EcosystemHeaderWrapper__links a:hover {
    color: $bright-orange; 
  } 

  .EcosystemHeader__EcosystemHeaderWrapper__links {
    position: absolute;
    display: none;
    max-width: 180px;
    height: auto;
    background-color: #ffffff;
    right: 0;
    top: 100%;
    z-index: 1;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .EcosystemHeader__EcosystemHeaderWrapper__links.show {
    display: block;
    transform: translateX(0);
  }

  .EcosystemHeader__EcosystemHeaderWrapper__Login > span,
  .EcosystemHeader__EcosystemHeaderWrapper__HeaderLinks > a:hover {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .logout {
    padding-left: 10px;
    margin-right: 10rem;
  }

  .EcosystemHeader__EcosystemHeaderWrapper__HeaderLink:last-child,
  .EcosystemHeader__EcosystemHeaderWrapper__MiddleLink:last-child {
    margin-right: 0;
    border-bottom: none;
  }

  .EcosystemHeader__EcosystemHeaderWrapper__HeaderLink a {
    background-color: #ffffff;
    margin-right: 0;
    color: $dark-blue;
  }

  .EcosystemHeader__EcosystemHeaderWrapper__MiddleLink {
    padding-left: 10px;
    margin-right: 0;
    border-right: none;
  }
  
  .EcosystemHeader__EcosystemHeaderWrapper__MiddleLink a {
    padding-right: 0;
    color: $dark-blue;
  }

  .EcosystemHeader__EcosystemHeaderWrapper__Login {
    color: $dark-blue;
  }

  .logout:hover {
    text-decoration: underline;
    color: $bright-orange;
  }
}

.EcosystemHeader__EcosystemHeaderWrapper__links {
  order: 3; /* This will position the links last in the flex container */
}

// TODO: change this stuff
.EcosystemHeader__LoginLink {
  a {
    color: $black;
    text-decoration: none;
    font-weight: bolder;

    &:hover {
      color: $bright-orange;
      text-decoration: underline;
    }
  }
}
