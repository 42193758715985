.carousel {
    margin: 10px;
    div {
      div {
        div {
          div{
            margin-right: 5px;

            :hover {
              filter: brightness(50%);
            }
          }
        }

      }
    }
    button,
    .slick-prev,
    .slick-next {
      &:before {
        color: #e55a00;
      }
    }
  }

  .carousel_item {
    background-color: #e55a00;
    opacity: 0.8;
    height: 100%;
    min-height: 150px;
    position: relative;
    width: 100%;
    cursor: pointer;
    

    h3 {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: large;
      font-weight: bold;
      text-align: center;
    }
  }

  .carousel_item_no_sub {
    background-color: #434343;
    opacity: 0.8;
    height: 100%;
    min-height: 150px;
    position: relative;
    width: 100%;
    cursor: pointer;
    
    h3 {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: large;
      font-weight: bold;
      text-align: center;
    }
  }


  // media query for tablets
  @media only screen and (max-width: 820px) {
    .carousel {
      margin: 10px;
      width: 83% !important;
      div {
        div {
          div {
            div{
              margin-right: 5px;
              :hover {
                filter: brightness(50%);
              }
            }
          }
        }
      }
      button,
      .slick-prev,
      .slick-next {
        &:before {
          color: #e55a00;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .main_img {
      padding: 10px;
      padding-top: 20px;
      img {
        width: 83% !important;
        height: 80%;
      }
    }

    .carousel {
      margin: 10px;
      width: 82% !important;
      div {
        div {
          div {
            div{
              margin-right: 5px;
              :hover {
                filter: brightness(50%);
              }
            }
          }
        }
      }
      button,
      .slick-prev,
      .slick-next {
        &:before {
          color: #e55a00;
        }
      }
    }
  }

  @media only screen and (max-width: 834px) {
    .carousel {
      margin: 10px;
      width: 86%;
      div {
        div {
          div {
            div{
              margin-right: 5px;
              :hover {
                filter: brightness(50%);
              }
            }
          }
        }
      }
      button,
      .slick-prev,
      .slick-next {
        &:before {
          color: #e55a00;
        }
      }
    }
    .carousel_item {
      background-color: #e55a00;
      opacity: 0.8;
      height: 100%;
      min-height: 150px;
      position: relative;
      width: 100%;
      cursor: pointer;
      h3 {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: large;
        font-weight: bold;
        text-align: center;
      }
    }
    .carousel_item_no_sub {
      background-color: #434343;
      opacity: 0.8;
      height: 100%;
      min-height: 150px;
      position: relative;
      width: 100%;
      cursor: pointer;
      h3 {
        color: white;
        position: absolute;
    
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: large;
        font-weight: bold;
        text-align: center;
      }
    }
 
  }