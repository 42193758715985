@import '../../stylesheets/themes/ecosystem-default/colors';
@import '../../stylesheets/themes/ecosystem-default/shadows';
@import '../../stylesheets/base/fonts';
@import '../../stylesheets/base/sizes';
@import '../../stylesheets/base/spacing';

.ContentPane {
  background-color: $white;
  box-shadow: $shadow-sm;
  padding: $spacing-small;

  &__Title {
    border-bottom: 1px solid $light-grey;
    color: $dark-blue;
    font-size: $h2-tablet;
    padding-bottom: $spacing-small;
   
  }

  &__Content {
    display: flex;
    margin: $spacing-semi 0;
  }
}