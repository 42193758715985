@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/base/fonts';

.PrivacyPolicy {
  margin: $spacing-standard auto;
  max-width: 60rem;
  p {
    margin-bottom: $spacing-small;
    color: $black;
  }

  &__padding{
    padding-left: 100px;
  }

  &__title-padding{
    padding-left: 10px;
  }
  
  &__border-left{
    border-left: 6px solid orange;
    height:fit-content;
    border-width: 0.3ch;  
  }

  &__subheading-padding{
    padding-left: 40px;
  }

  &__sub-subheading-padding{
    padding-left: 60px;
  }

  &__bullet-padding{
    padding-left: 25px;
  }

  ul {
    margin-left: 18px;

    ul,
    ol {
      margin: 0 0 $spacing-standard $spacing-standard;
      color: $black;
    }
  }

  &__list {
    color: $dark-grey;
    counter-reset: item;
    list-style: none;
  }

  &__list-item {
    counter-increment: item;
  }

  &__list-heading,
  &__sub-list-heading {
    color: $dark-blue;
    font-size: $h3;
    font-weight: normal;
    margin: $spacing-semi 0 $spacing-small;

    &:before {
      content: counters(item, '.');
      display: inline-block;
      margin-right: $spacing-tiny;
    }
  }

  &__sub-list {
    counter-reset: item;
    list-style: none;
  }

  &__sub-list-heading {
    font-size: $h4;
    margin: $spacing-small 0;
  }

  &__table {
    border-collapse: separate;
    border-spacing: $spacing-small;
  }
}
