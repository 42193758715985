@import 'colors';

html,
body {
  background: $bg-grey;
  color: $darkest-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .25px;
  line-height: 1.5;
}
