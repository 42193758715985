.contactForm {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    color: #333;
    margin-bottom: 20px;
    font-size: clamp(1.5rem, 4vw, 2.5rem);
  }

  .formGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }

  .fullWidth {
    grid-column: 1 / -1;
  }

  .inputGroup {
    margin-bottom: 15px;
  }

  .label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }

  .input,
  .select,
  .textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .textarea {
    height: 120px;
    resize: vertical;
  }

  .checkboxContainer {
    margin: 20px 0;
    display: flex;
    align-items: center;
    
    input[type="checkbox"] {
      margin-right: 10px;
    }
  }

  .submit {
    background-color: #5a7184;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4a6174;
    }
  }
}

@media (max-width: 600px) {
  .contactForm {
    padding: 15px;

    .formGrid {
      grid-template-columns: 1fr;
    }

    .input,
    .select,
    .textarea {
      font-size: 14px;
    }

    .submit {
      width: 100%;
    }
  }
}