  .modal {
    max-width: 1000px;
  }
  
  .modal_item {
    margin: 10px;
    position: relative;
    flex: 1 0 21%;
    display: block;
    width: 100%;
    height: 100%;
    h1 {
      padding-left: 0px;
      color: #e55a00;
    }
    p {
      margin-right: 10px;
    }
    ul {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .landingContainer {
    background-color: white;
}