.main_img {
  position: relative;
  width: 100%;
  height: 420px;
  background-size: cover;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding-left: 20px;
    color: #fff;
    background-color: rgba(65, 69, 73, 0.4);
    // background: linear-gradient(to right, rgba(100, 99, 103, 0.763) 15%, transparent 100%);
  }

  .investorUpdate, .updateText {
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  }

  .investorUpdate {
    font-size: 3rem;
    font-weight: bold;
    margin: 20px 0;
  }

  .updateText {
    font-size: 1.2rem;
    line-height: 1.4;
  }

  @media (max-width: 768px) {
    .overlay {
      padding-left: 10px;
    }

    .investorUpdate {
      font-size: 2.2rem;
    }

    .updateText {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    .overlay {
      padding-left: 5px;
    }

    .investorUpdate {
      font-size: 1.4rem;
    }

    .updateText {
      font-size: 0.9rem;
    }
  }
}
