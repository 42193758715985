@import '../../stylesheets/base/spacing';
@import '../../stylesheets/themes/ecosystem-default/colors';

.DatePickerGrid {
  display: block;
}

.DatePickerGridCell {
  position: relative;
  width: 100%;

  & input[disabled] {
    pointer-events: none;
  }

  .ClearIconBtn {
    position: absolute;
    top: 15px;
    right: 11px;
  }
}

.IconHidden {
  visibility: hidden;
}

.DatePickerGridCellRow {
  margin-bottom: $spacing-tiny;
}

.KeyboardSelectedMonth {
  & .react-datepicker__month-text--keyboard-selected {
    background-color: $brand-brightorange;
    border-radius: 4px;
    color: $ui-white;

    &:hover {
      background-color: $brand-brightorange;
      color: $ui-white;
    }
  }
}

.DateRangePickerContainer {
  width: inherit;

  & input {
    color: $text-default;
  }
}

.customToolTip {
  opacity: 1 !important;

  & p {
    margin: 0;
  }
}
