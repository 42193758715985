.main_img {
  padding: 10px;
  padding-top: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}

.ovnHeader {
  display: flex;
  padding-left: 10px;
  text-align: right;
}

.moreNews {
  display: flex;
  position: relative;
  padding-right: 25px;
  margin-left: auto;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  &:hover {
    .newsArrow {
      transform: translateX(14px);
      transition: all 0.3s;
      opacity: 5;
    }
  }
}

.newsArrow {
  font-size: medium;
  padding-left: 5px;
  cursor: pointer;
  color: #e55a00;
}

.modal {
  max-width: 1000px;
}

.modal_item {
  margin: 10px;
  position: relative;
  flex: 1 0 21%;
  display: block;
  width: 100%;
  height: 100%;
  h1 {
    padding-left: 0px;
    color: #e55a00;
  }
  p {
    margin-right: 10px;
  }
  ul {
    margin-left: 10px;
    margin-right: 10px;
  }
}

// Media Queries

@media only screen and (max-width: 820px) {
  .main_img {
    img {
      width: 85% !important;
      height: 80%;
    }
  }

  .ovnCarousel, .carousel, .card_container {
    width: 83% !important;
  }
}

@media only screen and (max-width: 800px) {
  .main_img {
    img {
      width: 83% !important;
      height: 80%;
    }
  }

  .ovnCarousel, .carousel, .card_container {
    width: 82% !important;
  }

  .moreNews {
    padding-right: 21% !important;
  }
}

@media only screen and (max-width: 834px) {
  .main_img {
    img {
      width: 88%;
      height: 88%;
    }
  }

  .ovnHeader {
    font-size: x-small;
  }

  .moreNews {
    font-size: medium;
    padding-right: 150px;
  }

  .newsArrow {
    font-size: smaller;
  }

  .ovnCarousel, .carousel, .card_container {
    width: 86% !important;
  }
}
