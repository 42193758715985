.ovnCarousel {
    margin: 10px;
    div {
      div {
        div {
          div {
            margin-right: 5px;
            
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
  
  .fillGrid {
    height: 260px;
  }
  
  .title {
    margin-top: 10px;
  }
  
  .pill {
    position: absolute;
    bottom: 0;
    padding-top: 10px;
    margin-bottom: 10px;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    
    a {
      margin-left: 5px !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  .badge {
    margin-right: 5px;
    cursor: pointer;
    height: fit-content;
  }
  
  .topright {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  @media only screen and (max-width: 820px) {
    .ovnCarousel {
      margin: 10px;
      width: 84% !important;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .ovnCarousel {
      margin: 10px;
      width: 83% !important;
    }
  }
  
  @media only screen and (max-width: 834px) {
    .ovnCarousel {
      margin: 10px;
      width: 86%;
    }
  }
  