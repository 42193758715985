@import '../../../stylesheets/base/sizes';
@import '../../../stylesheets/base/spacing';

@media screen and (min-width: 1500px) {
  .Wrapper {
    margin: 0 auto;
    width: 1490px;
  }
}

@media screen and (max-width: 1500px) {
  .Wrapper {
    margin: 0 $spacing-semi;
  }
}

@media screen and (max-width: 1024px) {
  .Wrapper {
    margin: 0 $spacing-standard;
  }
}
