@import '../../stylesheets/base/spacing';
@import '../../stylesheets/themes/ecosystem-default/colors';

.authenticationContent {
  max-width: 400px;
  margin: 20px auto 0 auto;
  border-radius: 4px;
  padding: $spacing-small;
  background-color: $white;
}

.authenticationContainer {
  height: 100%;
}

.login {
  min-height: fit-content;
}

.authenticatorH1 {
  margin-top: 0;
}

.authenticatorInput {
  margin-bottom: $spacing-smallest;
}

button {
  margin-bottom: $spacing-smallest;
  margin-right: $spacing-smallest;
}