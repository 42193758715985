@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/base/fonts';
@import '../../../stylesheets/themes/ecosystem-default/shadows';

.Button {
  background-color: $bright-orange;
  border: 2px solid $bright-orange;
  border-radius: $spacing-micro;
  color: $white;
  font-family: $font-primary;
  font-size: $intro-text;
  font-weight: bold;
  padding: $spacing-tiny $spacing-standard;

  &__icon {
    margin-right: $spacing-tiny;
  }

  &__badge {
    color: $mid-grey;
    font-size: $body-baseline;
    font-weight: normal;
    margin-left: $spacing-tiny;
  }

  &:active,
  &:hover {
    background-color: $dark-orange;
    border-color:$dark-orange;
  }

  &:active {
    outline: none;
  }

  &:focus {
    box-shadow: $shadow-focus;
    outline: none;

    &:active {
      box-shadow: none;
    }
  }

  &[disabled] {
    background: $light-grey;
    border-color: $light-grey;
    color: $mid-grey;
    cursor: auto;
  }

  &--secondary {
    background-color: $white;
    border-color: $bright-orange;
    color: $bright-orange;
    font-weight: normal;

    &:hover {
      background: $white;
      border-color: $dark-orange;
    }

    &:active {
      background-color: $bright-orange;
      border-color: $bright-orange;
      color: $white;
    }
  }

  &--with-icon {
    @extend .Button--secondary;

    &:active {
      .Icon path {
        fill: $white;
      }
    }
  }
}
