.card_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    padding: 2vw 0px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
  }
  
  .card_item {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
  }
  
  .cardMedia {
    grid-column: 1;
  }
  
  .cardContent {
    grid-column: 2;
  }
  
  @media only screen and (max-width: 820px) {
    .card_container {
      max-width: 83%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 20px;
      padding: 2vw 0px;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .card_container {
      max-width: 83%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 20px;
      padding: 2vw 0px;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
    }
  }
  
  @media only screen and (max-width: 834px) {
    .card_container {
      max-width: 86%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 20px;
      padding: 2vw 0px;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
    }
  }
  