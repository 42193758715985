@import '../../stylesheets/themes/ecosystem-default/colors';

.info {
  color: $grey;
  &:hover {
    color: $white;
  }
}


h4 {
  padding-bottom: 15px;
}

.history {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #e55a00;
  // color: rgb(235, 118, 28);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 900px) {
  .tableContainer {
    width: 100%;
    margin-top: 8px;
    overflow-x: auto; /* Horizontal scrolling for the release table */

    th:nth-child(3){  // width of 3rd column
      min-width: 250px; 
    }
    th:nth-child(6) {   // width of 6th column
      min-width: 460px; 
    }
  }

  .h1 {
    font-size: 4vw;  // Change font size to be relative to viewport width
    max-width: 100%;
  }
}