@import '../../../stylesheets/base/spacing';

.Loading {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: $spacing-standard 0;
  position: relative;
  width: 100%;
  &--stretch,
  &--compact {
    padding: 0;
  }
  &__Dots__Dot {
    animation: dot-fade 1s ease-in-out infinite;
    fill: rgb(107, 117, 125);
    opacity: .2;
    &:nth-child(2) {
      animation-delay: .3s;
    }
    &:nth-child(3) {
      animation-delay: .6s;
    }
  }
}

@keyframes dot-fade {
  0% {opacity: .2;}
  50% {opacity: .6;}
  100% {opacity: .2;}
}
