/* sizes.scss */

/* Border radius */
$border-radius: 4px;
$border-radius-large: 8px;

/* Widget dimensions */
$widget-height: 30vh;
$widget-width: 100%;

/* Context bar dimensions */
$context-bar-height: 62px;
$context-bar-min-height: 54px;
$context-bar-width: 100%;

/* Ecosystem dimensions */
$ecosystem-header-height: 78px;
$ecosystem-footer-height: 76px;

/* Sidebar dimensions */
$sidebar-width: 60px;

/* Checkbox dimensions */
$checkbox-width: 24px;

/* Desktop dimensions */
$desktop-width: 1366px;
$desktop-width-medium: 1440px;
$desktop-width-large: 1680px;
$desktop-width-extra-large: 1920px;

/* Tablet dimensions */
$tablet-portrait-width: 768px;

/* Media queries */
$media-desktop: 'only screen and (min-width: #{$desktop-width})';
$media-desktop-medium: 'only screen and (min-width: #{$desktop-width-medium})';
$media-desktop-large: 'only screen and (min-width: #{$desktop-width-large})';
$media-desktop-extra-large: 'only screen and (min-width: #{$desktop-width-extra-large})';
