@import 'colors';

$shadow-sm: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
$shadow-lg: 0px 4px 2px 0px rgba(0, 0, 0, 0.1);
$shadow-focus: 0 0 1px 2px $light-blue;
$shadow-inner: none;

/* stylelint-disable */
:export {
  shadow-sm: $shadow-sm;
  shadow-lg: $shadow-lg;
  shadow-focus: $shadow-focus;
}
