@import '../../../../../stylesheets/themes/ecosystem-default/colors';

.div2 {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .textContainer {
    position: relative;
    width: 60%;
    z-index: 2;
  }
  
  .text {
    display: inline-block;
    background-color: white;
    padding: 30px 20px;
    font-size: 30px;
    color: $ext-grey-700;
    font-weight: 600;
  }
  
  .left {
    margin-right: auto;
  }
  
  .right {
    margin-left: auto;
  }
  
  @media (max-width: 1000px) {
    .text {
      font-size: 26px;
      padding: 25px 17px;
    }
  }
  
  @media (max-width: 770px) {
    .text {
      font-size: 23px;
      padding: 20px 13px;
    }
  }
  
  @media (max-width: 500px) {
    .text {
      font-size: 21px;
      padding: 15px 11px;
    }
  }
