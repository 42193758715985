@import '../../../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../../../stylesheets/base/fonts';

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //gap: 2px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tile {
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --background-color-one: #fdc166;
  --background-color-two: #a387cb;
  --background-color-three: #d76789;
  --background-color-four: #87c5de;
  --background-color-five: #ffffff;
  --text-color: #000000;
  // background-color: var(--background-color-one);
  padding: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%; // Start at equal distribution
  align-items: left;
  height: auto;
  position: relative;
  font-weight: normal;
  font-size: 1.2em;
  color: var(--text-color);
  // margin: 1px;
}

.tileHeader {
  font-weight: bold;
  align-self: flex-start;
  justify-self: flex-start;
  width: 100%;
  margin: 0 0 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  color: $ext-grey-700;
  font-size: $h3;
}

.tile1 { background-color: var(--background-color-one); }
.tile2 { background-color: var(--background-color-two); }
.tile3 { background-color: var(--background-color-three); }
.tile4 { background-color: var(--background-color-four); }

.column {
  display: flex;
  flex-direction: column;
  height: 480px;
}

.tileContent {
  padding-left: 10px;
  color: $ext-grey-700;
  font-size: 17px;
}

@media (min-width: 1025px) {
  .tile1 { background-color: var(--background-color-one); padding: 40px 40px 40px 80px; }
  .tile2 { background-color: var(--background-color-two); padding: 40px 40px 40px 80px; }
  .tile3 { background-color: var(--background-color-three); padding: 40px 80px 40px 40px; }
  .tile4 { background-color: var(--background-color-four); padding: 40px 80px 40px 40px; }
}

@media (max-width: 769px) {
  .grid {
    grid-template-columns: 1fr;  // Change to a single column
  }

  .column {
    height: auto;
  }

  .tileHeader {
    font-size: $intro-text;
  }

  .tileContent {
    font-size: $caption;
  }
}
