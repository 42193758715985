@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/themes/ecosystem-default/z-index';
@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/base/fonts';

.Table {
  border-collapse: collapse;
  font-family: $font-primary;
  overflow: auto;
  position: relative;
  width: 100%;

  tr {
    &:not(:first-child),
    &:not(:last-child) {
      border-bottom: 1px solid $light-grey;
      border-spacing: 200px;
    }
  }

  td {
    // Firefox rendering bug: prevent showing a right border on table sorting
    border-right: 0px solid $white;
    position: relative;
    line-height: 28px;
  }

  td,
  th {
    padding: $spacing-small;
    vertical-align: top;
  }

  thead th {
    white-space: nowrap;
  }

  tbody {
    background: $white;
  }

  &__HeaderRow {
    &__sticky {
      position: sticky;
      top: 0;
    }

    &__stickyFirstColumnHeader {
      border-right: 1px solid $light-grey;
      box-shadow: 2px 0 4px 0 rgba(0, 0, 0, .15);
      left: 0;
      position: sticky;
      z-index: $z-index-sticky;
    }

    &__stickyFirstColumn {
      background: $white;
      td:first-child {
        background: $white;
        border-right: 1px solid $light-grey;
        box-shadow: 2px 0 4px 0 rgba(0, 0, 0, .15);
        left: 0;
        position: sticky;
      }

      td:nth-child(2) {
        background: $white;
        left: 2;
      }
    }
  }

  &__ColumnHeader {
    background: $mid-grey;
    border-right: 1px solid $light-grey;
    color: $white;
    font-size: $body-baseline;
    font-weight: $font-weight-heavy;

    &:last-child {
      border-right: unset;
    }

    &__ColumnSortable {
      cursor: pointer;
    }

    &__content {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &--compact {
        justify-content: flex-end;
      }
    }
  }
}