@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/fonts';
@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/themes/ecosystem-default/shadows';


.Input {
  &__field {
    border: 1px solid $grey;
    border-radius: 4px;
    color: $dark-grey;
    display: block;
    font-size: $body-baseline;
    padding: $spacing-tiny+$spacing-nano $spacing-small;
    width: 100%;

    &::placeholder {
      color: $mid-grey;
    }

    &:hover {
      border-color: $mid-grey;
      box-shadow: $shadow-sm;
    }

    &:focus {
      box-shadow: $shadow-focus;
      outline: none;
    }

    &--error {
      border-color: $red;
    }

    &--prepended {
      padding-left: 1.75rem;
      width: 100%;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__prepend {
    color: $dark-grey;
    left: .75rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  &__error {
    color: $red;
    display: block;
    font-size: $caption;
    margin-bottom: $spacing-tiny;
  }
}
