/* stylelint-disable color-no-hex */

/* Combined color palette */

/* January 2020 palette */
$white: #fff;
$dark-blue: #202d46;
$bright-orange: #e55a00;
$dark-orange: #cf4800;
$darkest-grey: #050505;
$dark-grey: #495057;
$mid-grey: #6b757d;
$light-grey: #ddd;
$grey: #ccc;
$light-blue: #8dc3cb;
$bg-grey: #f5f5f5;
$green: #35934b;
$red: #cc0018;
$teal: #00798b;
$purple: #681868;
$black: #000;
$pink: #e75370;
$yellow: #ebcc62;
$modal-background: rgba(0, 10, 20, 0.5);
$bright-orange-200: #ffd07c;

/* March 2021 palette */
/* UI colors */
$brand-darkorange: #cf4800;
$brand-brightorange: #e55a00;
$brand-lightorange: #fdefe6;
$brand-superlightorange: #fdf6f2;
$grey-900: #323842;
$grey-800: #414752;
$grey-700: #5c626e;
$grey-600: #6f7582;
$grey-500: #9499a3;
$grey-400: #bbc0c9;
$grey-300: #dadde3;
$grey-200: #ebecf0;
$grey-100: #f5f6f7;
$ui-white: #fff;
$ui-green: #167922;
$ui-red: #bc1c0e;

/* Data visualization colors */
$blue-800: #2b4c9b;
$blue-700: #3456a6;
$blue-600: #3c60b3;
$blue-500: #4368bd;
$blue-400: #6480c3;
$blue-300: #879bcc;
$blue-200: #afbbdb;
$blue-100: #dbe0eb;
$petrol-800: #428a81;
$petrol-700: #4b9a91;
$petrol-600: #4da9a1;
$petrol-500: #54b9b1;
$petrol-400: #63c8c2;
$petrol-300: #81d7d3;
$petrol-200: #b2edeb;
$petrol-100: #e2ffff;
$magenta-800: #97254f;
$magenta-700: #ac2b55;
$magenta-600: #c02d58;
$magenta-500: #d2335f;
$magenta-400: #d44a73;
$magenta-300: #d76789;
$magenta-200: #da91a7;
$magenta-100: #ddbbc5;
$yellow-800: #ee7c24;
$yellow-700: #f58a27;
$yellow-600: #fa9a2a;
$yellow-500: #ffa52f;
$yellow-400: #fdb244;
$yellow-300: #fdc166;
$yellow-200: #fed79a;
$yellow-100: #ffebcc;
$purple-800: #5c399b;
$purple-700: #673fa2;
$purple-600: #7249ad;
$purple-500: #7b4db3;
$purple-400: #8d69bb;
$purple-300: #a387c8;
$purple-200: #bfb0d4;
$purple-100: #ddd8e4;
$ext-grey-100: #d6d9dc;
$ext-grey-200: #c0c5c8;
$ext-grey-300: #9fa7ad;
$ext-grey-400: #7f888e;
$ext-grey-500: #6a757c;
$ext-grey-600: #535d63;
$ext-grey-700: #3b4145;
$ext-grey-800: #171a1c;
$red-100: #cba6a8;
$red-200: #bb7471;
$red-300: #b0504c;
$red-400: #ba302a;
$red-500: #bc1c0e;
$red-600: #ad0e0e;
$red-700: #9b0009;
$red-800: #8f0001;
$green-100: #bde5bd;
$green-200: #98d299;
$green-300: #73c375;
$green-400: #59b75b;
$green-500: #3dad42;
$green-600: #329d38;
$green-700: #24892c;
$green-800: #167922;

/* Descriptive tokens */
$text-heading: $grey-900;
$text-default: $grey-800;
$text-secondary-interactive: $grey-700;
$text-lighter: $grey-600;
$text-inactive: $grey-500;
$stroke: $grey-400;
$stroke-light: $grey-300;
$background-darkest: $grey-600;
$background-dark: $grey-300;
$background-medium: $grey-200;
$background-light: $grey-100;
$color-focus: $yellow-200;

/* Consolidated colors */
$colors: (
  'bg-grey': $bg-grey,
  'black': $black,
  'bright-orange': $bright-orange,
  'dark-blue': $dark-blue,
  'dark-grey': $dark-grey,
  'dark-orange': $dark-orange,
  'darkest-grey': $darkest-grey,
  'green': $green,
  'grey': $grey,
  'light-blue': $light-blue,
  'light-grey': $light-grey,
  'mid-grey': $mid-grey,
  'pink': $pink,
  'purple': $purple,
  'red': $red,
  'teal': $teal,
  'white': $white,
  'yellow': $yellow,
  'brand-darkorange': $brand-darkorange,
  'brand-brightorange': $brand-brightorange,
  'brand-superlightorange': $brand-superlightorange,
  'grey-800': $grey-800,
  'grey-700': $grey-700,
  'grey-600': $grey-600,
  'grey-500': $grey-500,
  'grey-400': $grey-400,
  'grey-300': $grey-300,
  'grey-200': $grey-200,
  'grey-100': $grey-100,
  'ext-grey-800': $ext-grey-800,
  'ext-grey-700': $ext-grey-700,
  'ext-grey-600': $ext-grey-600,
  'ext-grey-500': $ext-grey-500,
  'ext-grey-400': $ext-grey-400,
  'ext-grey-300': $ext-grey-300,
  'ext-grey-200': $ext-grey-200,
  'ext-grey-100': $ext-grey-100,
  'ui-white': $ui-white,
  'ui-green': $ui-green,
  'ui-red': $ui-red,
  'blue-800': $blue-800,
  'blue-700': $blue-700,
  'blue-600': $blue-600,
  'blue-500': $blue-500,
  'blue-400': $blue-400,
  'blue-300': $blue-300,
  'blue-200': $blue-200,
  'blue-100': $blue-100,
  'petrol-800': $petrol-800,
  'petrol-700': $petrol-700,
  'petrol-600': $petrol-600,
  'petrol-500': $petrol-500,
  'petrol-400': $petrol-400,
  'petrol-300': $petrol-300,
  'petrol-200': $petrol-200,
  'petrol-100': $petrol-100,
  'magenta-800': $magenta-800,
  'magenta-700': $magenta-700,
  'magenta-600': $magenta-600,
  'magenta-500': $magenta-500,
  'magenta-400': $magenta-400,
  'magenta-300': $magenta-300,
  'magenta-200': $magenta-200,
  'magenta-100': $magenta-100,
  'yellow-800': $yellow-800,
  'yellow-700': $yellow-700,
  'yellow-600': $yellow-600,
  'yellow-500': $yellow-500,
  'yellow-400': $yellow-400,
  'yellow-300': $yellow-300,
  'yellow-200': $yellow-200,
  'yellow-100': $yellow-100,
  'purple-800': $purple-800,
  'purple-700': $purple-700,
  'purple-600': $purple-600,
  'purple-500': $purple-500,
  'purple-400': $purple-400,
  'purple-300': $purple-300,
  'purple-200': $purple-200,
  'purple-100': $purple-100,
  'red-800': $red-800,
  'red-700': $red-700,
  'red-600': $red-600,
  'red-500': $red-500,
  'red-400': $red-400,
  'red-300': $red-300,
  'red-200': $red-200,
  'red-100': $red-100,
  'green-800': $green-800,
  'green-700': $green-700,
  'green-600': $green-600,
  'green-500': $green-500,
  'green-400': $green-400,
  'green-300': $green-300,
  'green-200': $green-200,
  'green-100': $green-100
);

$export-colors: (
  'white': $white,
  'dark-blue': $dark-blue,
  'bright-orange': $bright-orange,
  'dark-orange': $dark-orange,
  'darkest-grey': $darkest-grey,
  'dark-grey': $dark-grey,
  'mid-grey': $mid-grey,
  'light-grey': $light-grey,
  'grey': $grey,
  'light-blue': $light-blue,
  'bg-grey': $bg-grey,
  'green': $green,
  'red': $red,
  'teal': $teal,
  'purple': $purple,
  'black': $black,
  'pink': $pink,
  'yellow': $yellow,
  'brand-darkorange': $brand-darkorange,
  'brand-brightorange': $brand-brightorange,
  'brand-lightorange': $brand-lightorange,
  'brand-superlightorange': $brand-superlightorange,
  'grey-900': $grey-900,
  'grey-800': $grey-800,
  'grey-700': $grey-700,
  'grey-600': $grey-600,
  'grey-500': $grey-500,
  'grey-400': $grey-400,
  'grey-300': $grey-300,
  'grey-200': $grey-200,
  'grey-100': $grey-100,
  'ui-white': $ui-white,
  'ui-green': $ui-green,
  'ui-red': $ui-red,
  'blue-800': $blue-800,
  'blue-700': $blue-700,
  'blue-600': $blue-600,
  'blue-500': $blue-500,
  'blue-400': $blue-400,
  'blue-300': $blue-300,
  'blue-200': $blue-200,
  'blue-100': $blue-100,
  'petrol-800': $petrol-800,
  'petrol-700': $petrol-700,
  'petrol-600': $petrol-600,
  'petrol-500': $petrol-500,
  'petrol-400': $petrol-400,
  'petrol-300': $petrol-300,
  'petrol-200': $petrol-200,
  'petrol-100': $petrol-100,
  'magenta-800': $magenta-800,
  'magenta-700': $magenta-700,
  'magenta-600': $magenta-600,
  'magenta-500': $magenta-500,
  'magenta-400': $magenta-400,
  'magenta-300': $magenta-300,
  'magenta-200': $magenta-200,
  'magenta-100': $magenta-100,
  'yellow-800': $yellow-800,
  'yellow-700': $yellow-700,
  'yellow-600': $yellow-600,
  'yellow-500': $yellow-500,
  'yellow-400': $yellow-400,
  'yellow-300': $yellow-300,
  'yellow-200': $yellow-200,
  'yellow-100': $yellow-100,
  'purple-800': $purple-800,
  'purple-700': $purple-700,
  'purple-600': $purple-600,
  'purple-500': $purple-500,
  'purple-400': $purple-400,
  'purple-300': $purple-300,
  'purple-200': $purple-200,
  'purple-100': $purple-100,
  'red-800': $red-800,
  'red-700': $red-700,
  'red-600': $red-600,
  'red-500': $red-500,
  'red-400': $red-400,
  'red-300': $red-300,
  'red-200': $red-200,
  'red-100': $red-100,
  'green-800': $green-800,
  'green-700': $green-700,
  'green-600': $green-600,
  'green-500': $green-500,
  'green-400': $green-400,
  'green-300': $green-300,
  'green-200': $green-200,
  'green-100': $green-100
);
