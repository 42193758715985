@import '../../../../../stylesheets/themes/ecosystem-default/colors';

.statSectionWrapper {
  background-color: #ffedcc;
  padding: 56px 56px 28px;
  width: 100%;
}

.statSection {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

.statItem {
  text-align: left;
  padding: 20px;
  border-left: 4px solid $ext-grey-700;
  flex: 1 1 30%; /* Grow, shrink, and initial basis */
  min-width: 33%; /* Minimum width for each item */

  &:first-child {
    border: none;
  }

  @media (max-width: 1024px) {
    min-width: 50%;
    max-width: 50%;
    border: none;

    &:nth-child(2n) {
      border-left: 4px solid $ext-grey-700;
    }
  }

  @media (max-width: 600px) {
    text-align: center;
    padding: 10px 0;
    border: none;

    &:nth-child(2n) {
      border-left: none;
    }
  }

  h2 {
    font-size: 2.4rem;
    margin-bottom: 10px;
    font-weight: 600;
    color: $ext-grey-700;

    @media (max-width: 600px) {
      font-size: 2rem;
    }
  }
  
  p {
    font-size: 1.3rem;
    color: $ext-grey-700;

    @media (max-width: 600px) {
      font-size: 1.0rem;
    }
  }
}
