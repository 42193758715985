.modal {
    max-width: 1000px;
  }
  
  .modal_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .modal_item {
    margin: 10px;
    position: relative;
    flex: 1 0 21%;
    display: block;
    width: 100%;
    height: 100%;
    
    h1 {
      padding-left: 0px;
      color: #e55a00;
    }
    
    p {
      margin-right: 10px;
    }
    
    ul {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  
  hr.modalContentSeparator {
    border-top: 3px solid #bbb;
  }
  
  @media only screen and (max-width: 820px) {
    .modal {
      max-width: 90%;
    }
  
    .modal_item {
      margin: 5px;
      flex: 1 0 50%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .modal_item {
      margin: 2px;
      flex: 1 0 100%;
    }
  }
  