@import '../../../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../../../stylesheets/base/fonts';

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
}

.tile {
  --background-color-one: #fdc166;
  --background-color-two: #d76789;
  --background-color-three: #a387cb;
  --background-color-four: #87c5de;
  --background-color-five: #fdc166;
  --background-color-six: #9fa7ad;
  --background-color-seven: #d76789;
  --background-color-eight: #a387cb;
  --background-color-nine: #87c5de;

  --text-color: #000000;
  // background-color: var(--background-color-one);
  padding: 24px;
  margin-bottom: 14px;
  transition: flex-grow 0.5s ease, flex-shrink 0.5s ease;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%; // Start at 0% for equal distribution
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  position: relative;
  font-weight: bold;
  font-size: 1.3em;
  color: var(--text-color);

  img {
    width: 28px;
    height: 28px;
    position: relative;
    margin: auto 10px 0px;
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 0.3s ease, opacity 0.3s ease;
    &.hidden {
      opacity: 0;
      transform: rotate(90deg);
      transition: transform 0.3s ease, opacity 0.2s 0.3s ease;
    }
  }
}

.tileHeader {
  align-self: flex-start;
  justify-self: flex-start;
  width: 100%;
  margin: 0 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  color: $ext-grey-700;
  transition: opacity 0.3s, max-height 0.2s 0.2s ease-out;
  max-height: 25px;
  opacity: 1;
  font-weight: bold;
  font-size: $h4;

  &.hidden {
    opacity: 0;
    max-height: 5px;
  }
}

.tile1 { background-color: var(--background-color-one); }
.tile2 { background-color: var(--background-color-two); }
.tile3 { background-color: var(--background-color-three); }
.tile4 { background-color: var(--background-color-four); }
.tile5 { background-color: var(--background-color-five); }
.tile6 { background-color: var(--background-color-six); }
.tile7 { background-color: var(--background-color-seven); }
.tile8 { background-color: var(--background-color-eight); }
.tile9 { background-color: var(--background-color-nine); }

.column {
  display: flex;
  flex-direction: column;
  height: 600px;
  min-width: 0; /* Allow the column to shrink */
  max-width: 100%; /* Prevent exceeding the parent width */
}

.tileExpandedContent {
  font-weight: normal;
  font-size: 0.8em;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s, padding 0.5s;
  opacity: 0; // Start with content invisible
  color: $ext-grey-700;
}

.collapsed {
  flex-grow: 0;
}

.expanded {
  flex-grow: 3;
}

.tile.expanded .tileExpandedContent {
  opacity: 1;
  padding-left: 10px;
}

/* single column for smaller screens */
@media (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .column {
    height: auto;
    max-height: none;
  }

  .tile{
    margin-bottom: 14px;
  }
}

@media (max-width: 769px) {
  .tileHeader {
    font-size: $intro-text;
  }

  .tileExpandedContent {
    font-size: 0.7em;
  }
}
