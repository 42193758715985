@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

$font-primary: 'Lato', sans-serif;
$font-weight-heavy: 700;

/* Design System Jan 2020 */

$body-baseline: 16px;
$intro-text: 18px;
$micro: 12px;
$caption: 14px;
$giant: 48px;
$h1: 32px;
$h1-tablet: 28px;
$h2: 28px;
$h2-tablet: 24px;
$h3: 24px;
$h3-tablet: 20px;
$h4: 20px;

/* stylelint-disable */
:export {
  font-primary: $font-primary;
  micro: $micro;
  caption: $caption;
}

/* stylelint-enable */
